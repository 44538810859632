//JSON data for about us page template

/**
 * developmentLayout.js
 * copyright © 2022 Ania Management
 */

import aboutusmobileimg from "./assets/images/AmoryRendering.png";
import aboutusdesktopimg from "./assets/images/AmoryRendering.png";

export const developmentData = [
  {
    pageTitle: "Development",
    url: "development",
    desktopImg: aboutusdesktopimg,
    mobileImg: aboutusmobileimg,
    title: "Revitalizing neighborhoods to make them safe and family-friendly.",
    p1: "ANIA Management seeks to grow and help neighborhoods in a sustainable way with the goal of improving the lives of residents and the community. ANIA looks to build the kind of neighborhoods tenants want to live in.",
    p2: "This involves ongoing communication with civic and community leaders to work towards common goals, including community revitalization, improving neighborhood safety, cleaner streets and supporting educational programs to create a better skilled workforce. It includes hiring locally and working with local contractors and suppliers. ",
    p3: "It also includes being involved with and supporting local organizations in sports and education. ANIA believes that a win for the local residents is a win for everyone. ",
  },
];
