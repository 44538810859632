/**
 * termsOfUse.js
 * copyright © 2022 Ania Management
 */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { termOfUseData } from "../../src/termOfUseTemplateData";
import parse from "html-react-parser";

function TermsofUse() {
  const { pathname } = useLocation();
  let urlTosearch = pathname.split("/");
  urlTosearch = urlTosearch[1];
  const [pageData, setPageData] = useState([]);

  //for putting data of current page to state on page load
  useEffect(() => {
    window.scrollTo(0, 0);
    if (urlTosearch) {
      const result = termOfUseData.filter(
        (val) => val.url.toLowerCase() === urlTosearch.toLowerCase()
      );
      if (result && result.length > 0) {
        setPageData(result);
      } else {
        setPageData([]);
      }
    }
  }, [urlTosearch]);

  return (
    <section className="content-wrapper terms-and-conditions">
      {pageData && pageData.length > 0 ? (
        <div className="ania-content">
          <div className="container-fluid px-0">
            <div className="main-content border-bottom">
              <div className="col-lg-12 px-lg-5 px-4">
                <h1 className="page-title page-title-lg mb-0">
                  {pageData[0].pageTitle}
                </h1>
              </div>
            </div>
          </div>
          <div className="page-content-section">
            <div className="container-width terms-and-use">
              <h2>{pageData[0].heading1}</h2>
              {pageData[0].section1.map((val, i) => (
                <p key={i}>{parse(val)}</p>
              ))}
              <h3>{pageData[0].heading2}</h3>
              {pageData[0].section2.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData[0].heading3}</h3>
              {pageData[0].section3.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <ul>
                {pageData[0].list3.map((val, i) => (
                  <li key={i}>{val}</li>
                ))}
              </ul>
              <h3>{pageData[0].heading4}</h3>
              {pageData[0].section4.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <ul>
                {pageData[0].list4_1.map((val, i) => (
                  <li key={i}>{val}</li>
                ))}
              </ul>
              <p>{pageData[0].sub4_1}</p>
              <ul>
                {pageData[0].list4_2.map((val, i) => (
                  <li key={i}>{val}</li>
                ))}
              </ul>
              <p>{pageData[0].sub4_2}</p>
              <h3>{pageData[0].heading5}</h3>
              {pageData[0].section5.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData[0].heading6}</h3>
              {pageData[0].section6.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <ul>
                {pageData[0].list6_1.map((val, i) => (
                  <li key={i}>{val}</li>
                ))}
              </ul>
              <p>{pageData[0].sub6}</p>
              <ul>
                {pageData[0].list6_2.map((val, i) => (
                  <li key={i}>{val}</li>
                ))}
              </ul>
              <h3>{pageData[0].heading7}</h3>
              {pageData[0].section7.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData[0].heading8}</h3>
              {pageData[0].section8.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData[0].heading9}</h3>
              {pageData[0].section9.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData[0].heading10}</h3>
              {pageData[0].section10.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <ul>
                {pageData[0].list10_1.map((val, i) => (
                  <li key={i}>{val}</li>
                ))}
              </ul>

              <p>{pageData[0].sub10_1}</p>
              <ul>
                {pageData[0].list10_2.map((val, i) => (
                  <li key={i}>{val}</li>
                ))}
              </ul>
              <p>{pageData[0].sub10_2}</p>
              <h3>{pageData[0].heading11}</h3>
              {pageData[0].section11.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData[0].heading12}</h3>
              {pageData[0].section12.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData[0].heading13}</h3>
              {pageData[0].section13.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData[0].heading14}</h3>
              {pageData[0].section14.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData[0].heading15}</h3>
              {pageData[0].section15.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData[0].heading16}</h3>
              {pageData[0].section16.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData[0].heading17}</h3>
              {pageData[0].section17.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData[0].heading18}</h3>
              {pageData[0].section18.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData[0].heading19}</h3>
              {pageData[0].section19.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData[0].heading20}</h3>
              {pageData[0].section20.map((val, i) => (
                <p key={i}>{parse(val)}</p>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <h1
          style={{ textAlign: "center", margin: "120px 50px", color: "grey" }}
        >
          {" "}
          No Data Found For This Url
        </h1>
      )}
    </section>
  );
}
export default TermsofUse;
