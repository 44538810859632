/**
 * newSlideShow.js
 * copyright © 2022 Ania Management
 */
import React from "react";
import "react-slideshow-image/dist/styles.css";
// import "../assets/css/slideShow.css";

import { Fade } from "react-slideshow-image";

const NewSlideShow = ({ slideShowImages = [], slideShowProperties }) => {
  return (
    <div className="slide-container">
      <Fade {...slideShowProperties}>
        {slideShowImages.map((each, index) => (
          <div className="main-banner">
            <img
              key={index}
              className="img-fluid img-main"
              style={{ width: "100%" }}
              src={each}
              alt=""
            />
            <p className="welcome">welcome to ania</p>
          </div>
        ))}
      </Fade>
    </div>
  );
};

export default NewSlideShow;
