/**
 * leadership.js
 * copyright © 2022 Ania Management
 */
import React, { useEffect, useState } from "react";
// import mathew from "../assets/images/Matthew1.png";
// import charles from "../assets/images/Charles1.png";
// import eric from "../assets/images/EricY1.png";
// import daniel from "../assets/images/DanielGonzalez1.png";
// import jonathan from "../assets/images/JonathenThen1.png";
// import alex from "../assets/images/AlexK1.png";
// import madeline from "../assets/images/MadelineRijo1.png";
// import isreily from "../assets/images/IsreilyCruz1.png";
// import ashley from "../assets/images/AshleyF1.png";
import mathew from "./../assets/images/leadership/matthew.jpg";
import charles from "./../assets/images/leadership/charles.jpg";
import erik from "./../assets/images/leadership/erik.jpg";
import daniel from "./../assets/images/leadership/daniel.jpg";
import ashley from "./../assets/images/leadership/ashley.jpg";
import nestor from "./../assets/images/leadership/nestor.jpg";
import mike from "./../assets/images/leadership/mike.jpg";

import mathewDetails from "./../assets/images/leadership/details/matthew.jpg";
import charlesDetails from "./../assets/images/leadership/details/charles.jpg";
import erikDetails from "./../assets/images/leadership/details/erik.jpg";
import danielDetails from "./../assets/images/leadership/details/daniel.jpg";
import ashleyDetails from "./../assets/images/leadership/details/ashley.jpg";
import nestorDetails from "./../assets/images/leadership/details/nestor.jpg";
import mikeDetails from "./../assets/images/leadership/details/mike.jpg";


import "./../assets/css/leadership.css";
import closeImage from "./../assets/images/crosss.png";
import { useParams, useNavigate } from "react-router-dom";

const data = [
  {
    name: "Matthew Florio",
    role: "President",
    bio: [
      "With expertise built over 16 years of experience, Matthew is instrumental in shaping ANIA’s acquisition and development strategies.",
      "His long-standing relationships with local leaders and businesses are essential components of the ANIA mission to rebuild and revitalize communities.",
      "Most recently, Matthew has directed development projects including the Greenbaum building, and the Great Falls Historic District Restoration, and the current construction at The Armory.",
      "A recognized philanthropist, he also serves on the Ridgefield Park Board of Recreation.",
    ],
    image: mathew,
    imageDetails: mathewDetails
  },
  {
    name: "Charles Florio",
    role: "CEO",
    bio: [
      "As CEO, Charles is the driving force behind ANIA’s mission-focused growth, wielding 21 years of experience across property acquisition, planning, management, and development.",
      "Charles has worked tirelessly to revitalize the city of Paterson, initiating locally-focused hiring policies and partnering with local agencies to improve accessibility, infrastructure, and quality of life.",
      "He is a member of the FTI Group and former member of the Downtown Paterson Special Improvement District. Charles holds his degree from Saint Peter’s University.",
    ],
    image: charles,
    imageDetails: charlesDetails
  },
  {
    name: "Erik Yu",
    role: "Controller",
    bio: [
      "Erik brings 10 years of experience to his role as Financial Controller, providing steadfast oversight across accounting operations, cash management, and financial planning. ",
      "With his strong financial leadership, Erik is pivotal in furthering the ANIA mission to develop high quality housing in revitalized communities. ",
      "Previous to joining ANIA, Erik led project analysis at TD Bank. He holds his degree from Rutgers University, New Brunswick.",
    ],
    image: erik,
    imageDetails: erikDetails
  },
  {
    name: "Mike Powell",
    role: "Managing Director",
    bio: [
      "Mike has over 20 years of experience in urban revitalization, economic development, strategic management, public policy, and sustainability. ",
      "As Managing Director, he is responsible for creating future development opportunities, securing public and private sector investment and creating innovative partnerships that expand the ANIA portfolio and mission. ",
      "Mike has led, researched, published, and presented on community-based planning both nationally and internationally. He holds a Masters degree in City & Regional Planning from Cornell University.",
    ],
    image: mike,
    imageDetails: mikeDetails
  },
  {
    name: "Daniel Gonzalez",
    role: "Vice President (Acquisitions)",
    bio: [
      "As Vice President, Daniel oversees property acquisitions, pre-development and construction, playing a key role in ANIA’s commitment to train and hire locally. ",
      "With seven years of ground-up development experience, Daniel provides critical insights and expertise to property evaluations and purchasing processes. He also takes the lead in ensuring our operational efficiencies. ",
      "Daniel holds his Bachelor of Science in Finance from New Jersey City University.",
    ],
    image: daniel,
    imageDetails: danielDetails
  },
  {
    name: "Ashley Florio",
    role: "Sr. Property Manager",
    bio: [
      "Ashley brings eight years of experience to the team, acting as point person across all property management efforts. ",
      "Key responsibilities include overseeing landlord/tenant matters, handling leasing activity, and acting as office manager. Ashley is known for her problem-solving capabilities, and is pivotal in keeping day-to-day functions operating smoothly. ",
      "She earned her Masters from William Paterson University.",
    ],
    image: ashley,
    imageDetails: ashleyDetails
  },
  {
    name: "Nestor Guzman Jr. Esq.",
    role: "Attorney",
    bio: [
      "Nestor brings 27 years of experience in real estate and business law to his role as In-House Counsel.  ",
      "His responsibilities include overseeing ANIA’s interactions with City, State and Federal administrative and regulatory agencies, as well as engaging with City, County and State Section 8 public assistance programs. He also spearheads tax appeals, civil claims, and representation of property owners. ",
      "He holds his Juris Doctor (JD) Degree from Rutgers University and his Bachelor of Arts from Seton Hall.",
    ],
    image: nestor,
    imageDetails: nestorDetails
  },
];

function Leadership() {
  const [selectedProfile, setSelectedProfile] = useState(null);
  const {name} = useParams()
  const navigate = useNavigate()
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(()=>{
    if(selectedProfile){
      window.scrollTo(0, 0);
    }
  }, [selectedProfile])

  useEffect(()=>{
    if(name){
      const _temp = data.find(x=> x.name?.toLowerCase() === name?.split('-')?.join(' ')?.toLowerCase());
      if(_temp){
        setSelectedProfile(_temp);
      }else{
        setSelectedProfile(null);
      }
    }else{
      setSelectedProfile(null);
    }
  }, [name])

  return (
    <section className="content-wrapper leadership ">
      <div className="ania-content">
        <div className="container-fluid px-0">
          <div className="main-content border-bottom">
            <div className="col-lg-12 px-lg-5 px-4">
              <h1 className="page-title page-title-lg mb-0">Leadership</h1>
            </div>
          </div>
        </div>
        {selectedProfile?.name ? (
          <>
            <div className="breadcrumb-width">
              <div className="col-lg-12 mb-lg-4">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item" role="button" style={{cursor: "pointer"}} onClick={()=> navigate("/leadership", {replace: true})}>Leadership</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {selectedProfile?.name}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="container container-width leadership-members-details-container">
              <div className="row">
                <div className="col-12 d-flex  justify-content-end">
                <button
                  type="button"
                  className="profile-close-button"
                  onClick={() => {
                    
                      navigate("/leadership", {replace: true})
                    
                  }}
                >
                  <img src={closeImage} style={{ width: 86 }} />
                </button>
                </div>
              </div>
              <div className="row leadership-details-content-row">
                <div className="col-md-6 text-center leadership-details-image-wrapper">
                  <img
                    src={selectedProfile?.imageDetails}
                    alt="Matthew Florio"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-6">
                  <p className="leadership-details-name m-0 p-0">
                    {selectedProfile?.name}
                  </p>
                  <p className="leadership-details-role p-0">
                    {selectedProfile?.role}
                  </p>
                  {selectedProfile?.bio?.map((x) => (
                    <p className="leadership-details-bio m-0 mt-4 p-0">{x}</p>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="container-width leadership-members-container">
            <div className="row justify-content-start">
              {data?.map((x) => {
                return (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-1 leadership-card">
                    <div className="leadership-img-wrapper">
                      <img
                        src={x.image}
                        alt="Matthew Florio"
                        className="leadership-img"
                      />
                      <div class="leadership-img-gradient">
                        <button
                          class="profile-button"
                          onClick={() => {
                            // setSelectedProfile(x);
                            navigate("/leadership/"+x.name?.split(' ')?.join("-"))
                          }}
                        >
                          Profile
                        </button>
                      </div>
                    </div>
                    <div className="leadership-name">{x.name}</div>
                    <div className="leadership-role">{x.role}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* <div className="container-width leadership-content-container">
          <div className="leadership-content">
            <div className="user">
              <div className="user-pic">
                <img src={mathew} alt="" />
              </div>
              <div className="user-details">
                <h6>Matthew Florio</h6>
                <strong>President</strong>
              </div>
              <p className="user-description">
                An esteemed real estate developer, sports enthusiast, and a
                philanthropist. He brings over fifteen years of real estate
                experience and relationships, acquiring and developing over
                4,500 units. Mr. Florio’s real estate holdings are over 700
                million.
              </p>
            </div>
            <div className="user">
              <div className="user-pic">
                <img src={charles} alt="" />
              </div>
              <div className="user-details">
                <h6>Charles Florio</h6>
                <strong>CEO</strong>
              </div>
              <p className="user-description">
                Charles Florio is involved in all aspects of running the
                business. His vision helped the company grow to 250 employees,
                4,500 units and expand across four states.
              </p>
            </div>
            <div className="user">
              <div className="user-pic">
                <img src={eric} alt="eric" />
              </div>
              <div className="user-details">
                <h6>Erik Yu</h6>
                <strong>Controller</strong>
              </div>
              <p className="user-description">
                Erik Yu provides oversight for the company’s accounting
                operation, cash management and financial planning.
              </p>
            </div>
            <div className="user">
              <div className="user-pic">
                <img src={daniel} alt="daniel" />
              </div>
              <div className="user-details">
                <h6>Daniel Gonzalez</h6>
                <strong>Vice President (Acquisitions)</strong>
              </div>
              <p className="user-description">
                Daniel Gonzalez is responsible for property acquisitions,
                ground-up development and ensuring the companies operational
                efficiencies.
              </p>
            </div>
            <div className="user">
              <div className="user-pic"></div>
              <div className="user-details">
                <h6>Jennifer Martinez</h6>
                <strong>Office Administrator</strong>
              </div>
            </div>
            <div className="user">
              <div className="user-pic">
                <img src={ashley} alt="ashley" />
              </div>
              <div className="user-details">
                <h6>Ashley Florio</h6>
                <strong>Sr. Property Manager</strong>
              </div>
            </div>
            <div className="user">
              <div className="user-pic">
                <img src={jonathan} alt="" />
              </div>
              <div className="user-details">
                <h6>Jonathan Then</h6>
                <strong>Property Manager</strong>
              </div>
            </div>
            <div className="user">
              <div className="user-pic">
                <img src={alex} alt="alex" />
              </div>
              <div className="user-details">
                <h6>Alex Kasperowicz</h6>
                <strong>Property Manager</strong>
              </div>
            </div>
            <div className="user">
              <div className="user-pic">
                <img src={madeline} alt="madeline" />
              </div>
              <div className="user-details">
                <h6>Madeline Rijo</h6>
                <strong>Property Manager</strong>
              </div>
              <p className="user-description">
                  In addition to being a Property Manager, Madeline Rijo works
                  with local and state programs to ensure ANIA's relationship
                  with subsidy programs run smoothly.
                </p>
            </div>
            <div className="user">
              <div className="user-pic">
                <img src={isreily} alt="isreily" />
              </div>
              <div className="user-details">
                <h6>Isreily Cruz</h6>
                <strong>Property Manager</strong>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default Leadership;
