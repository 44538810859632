/**
 * nomatch.js
 * copyright © 2022 Ania Management
 */
import React from "react";

function NoMatch() {
  return (
    <div>
      <h3>
        ERROR:404 No Page Available, Kindly select a Valid Route from the Navbar
        Toggler Button
      </h3>
    </div>
  );
}
export default NoMatch;
