//JSON data for privacy policy page template
/**
 * privacyPolicyTemplateData.js
 * copyright © 2022 Ania Management
 */

export const privacyPolicyData = {
  pageTitle: "Privacy Policy",
  url: "privacy-policy",
  heading1: "Privacy Statement",
  section1: [
    "We take your privacy seriously and this privacy statement explains how https://www.aniamanagement.com (collectively, “ANIA,” “Company,” “we,” “us,” or “our”) collect, use, share and process your information.",
  ],
  heading2: "Collection and Use of Personal Data",
  section2: [
    "Personal data is information that can be used to directly or indirectly identify you. Personal data also includes anonymous data that is linked to information that can be used to directly or indirectly identify you. Personal data does not include data that has been irreversibly anonymized or aggregated so that it can no longer enable us, whether in combination with other information or otherwise, to identify you.",
  ],
  heading3: "Promoting safety and security",
  section3: [
    "We abide by the principles of legality, legitimacy, and transparency, use, and process the least data within a limited scope of purpose, and take technical and administrative measures to protect the security of the data. We use personal data to help verify accounts and user activity, as well as to promote safety and security, such as by monitoring fraud and investigating suspicious or potentially illegal activity or violations of our terms or policies. ",
    "Here is a description of the types of personal data we may collect and how we may use it:",
  ],
  heading4: "What Personal Data We Collect",
  list1: [
    {
      title: "Data you provide:",
      sub: [
        "We collect the personal data you provide when you interact with us, such as when you contact us or show your interest to explore career opportunities with us.",
      ],
    },
    {
      title: "Data about use of our services and products:",
      sub: [
        "When you visit our website, we may collect data about the type of device you use, your device's unique identifier, the IP address of your device, your operating system, the type of Internet browser that you use, usage information, diagnostic information, and location information from or about the computers, phones, or other devices on which you access our products or services.",
      ],
    },
  ],
  heading5: "How We Use Your Personal Data",
  section5: [
    "Generally speaking, we use personal data to provide, improve, and develop our services, to communicate with you, to offer you targeted advertisements and services, and to protect us and our customers.",
  ],
  list2: [
    {
      title: "Providing, improving, and developing our products and services:",
      sub: [
        "We use personal data to help us provide, improve, and develop our services, and advertising. This includes using personal data for purposes such as data analysis, research, and audits. Such processing is based on our legitimate interest in offering you services and for business continuity. If you enter a contest, or other promotion, we may use the personal data you provide to administer those programs. Some of these activities have additional rules, which may contain further data about how we use personal data, so we encourage you to read those rules carefully before participating.",
      ],
    },
    {
      title: "Communicating with you:",
      sub: [
        "Subject to your prior express consent, we may use personal data to send you marketing communications in relation to our services, communicate with you about your account, and inform you about our policies and terms. If you no longer wish to receive email communications for marketing purposes, please contact us to opt-out. We also may use your data to process and respond to your requests when you contact us. Subject to your prior express consent, we may use personal data to personalize your experience with our services and on third-party websites and applications and to determine the effectiveness of our promotional campaigns.",
        "NOTE: For any of the uses of your data described above that require your prior express consent, note that you may withdraw your consent by contacting us.",
      ],
    },
  ],
  heading6: 'Definition of "Cookies"',
  section6: [
    'Cookies are small pieces of text used to store information on web browsers. Cookies are widely used to store and receive identifiers and other information on computers, phones, and other devices. We also use other technologies, including data we store on your web browser or device, identifiers associated with your device, and other software, for similar purposes. In this Cookie Statement, we refer to all of these technologies as "cookies."',
  ],
};
