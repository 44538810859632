/**
 * footer.js
 * copyright © 2022 Ania Management
 */
import React from "react";
import facebook from "../assets/images/facebook.png";
import instagram from "../assets/images/instagram.png";
import linkedin from "../assets/images/linkedin.png";
import pinterest from "../assets/images/pinterest.png";
import { Link } from "react-router-dom";
import Home from "../pages/home";
import ContactUs from "../pages/contactUs";
import AboutUs from "../pages/aboutus";
import FaqPage from "../pages/faq";
import Leadership from "../pages/leadership";
import TermsofUse from "../pages/termsOfUse";
import Properties from "../pages/properties";
import FindUnit from "../pages/findaunit";
import NewPatersonPage from "../pages/newpatersonPage";
import ProspectiveTenants from "../pages/prospectiveTenants";
import Careers from "../pages/careers";
import "../assets/css/home.css";

function Footer() {
  //for fetching current year to show in footer
  const currentYear = new Date().getFullYear();
  return (
    <footer className="ania-footer">
      <div className="container-width">
        <div className="d-lg-flex d-md-flex justify-content-between align-items-start">
          <div className="d-lg-flex d-md-flex justify-content-between align-items-start footer-left-half">
            <div>
              <ul className="ania-quicklinks">
                <li>
                  <Link to="/find-unit" component={<FindUnit />}>
                    find a unit
                  </Link>
                </li>
                <li>
                  <Link to="/properties" component={<Properties />}>
                    properties
                  </Link>
                </li>
                <li>
                  <Link to="/whypaterson" component={<NewPatersonPage />}>
                    Why Paterson
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" component={<AboutUs />}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/contact" component={<ContactUs />}>
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to="/careers" component={<Careers />}>
                    Careers
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <ul className="ania-quicklinks">
                <li>
                  <Link
                    to="/prospective-tenants"
                    component={<ProspectiveTenants />}
                  >
                    Prospective Tenants
                  </Link>
                </li>
                <li>
                  <Link to="/faq-page" component={<FaqPage />}>
                    FAQs
                  </Link>
                </li>
                <li>
                  <Link to="/leadership" component={<Leadership />}>
                    leadership
                  </Link>
                </li>
                <li>
                  <Link to="/terms-of-use" component={<TermsofUse />}>
                    Terms of Use
                  </Link>
                </li>
                {/* <li className="text-transform-auto last">
                  <Link to="/" component={<Home />}>
                    Employee Login
                  </Link>
                </li> */}
              </ul>
            </div>
            <div>
              {" "}
              <div className="footer-contactus">
                <p className="title text-uppercase">contact us</p>
                <p className="email">
                  <a
                    className="footer-contact-links"
                    href="mailto:info@aniamanagement.com"
                  >
                    info@aniamanagement.com
                  </a>
                </p>
                <p className="phone">
                  phone:{" "}
                  <a className="footer-contact-links" href="tel:9735237690">
                    {" "}
                    (973) 523-7690
                  </a>
                </p>
                <p className="address last">
                  175, Broadway <br />
                  Paterson, NJ 07505
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="footer-social">
              <p className="title text-lg-center">Follow us on Social</p>
              <ul className="social-icons last">
                <li>
                  <a
                    href="https://www.facebook.com/AniaGroup"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={facebook} className="img-fluid" alt="facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/AniaGroup/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={instagram}
                      className="img-fluid"
                      alt="instagram"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.pinterest.com/AniaGroup/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={pinterest}
                      className="img-fluid"
                      alt="pinterest"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/aniagroup"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedin} className="img-fluid" alt="linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-copyright">
              <p className="copyright">
                All Rights Reserved &reg; ANIA Management - {currentYear}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
