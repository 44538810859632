/**
 * management.js
 * copyright © 2022 Ania Management
 */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { managementData } from "../managementLayout";
import "../assets/css/aboutus.css";

function Management() {
  const { pathname } = useLocation();
  let urlTosearch = pathname.split("/");
  urlTosearch = urlTosearch[1];
  const [pageData, setPageData] = useState([]);

  //for opening page from top whenever user lands on this page
  useEffect(() => {
    window.scrollTo(0, 0);
    if (urlTosearch) {
      const result = managementData.filter(
        (val) => val.url.toLowerCase() === urlTosearch.toLowerCase()
      );
      if (result && result.length > 0) {
        setPageData(result);
      } else {
        setPageData([]);
      }
    }
  }, [urlTosearch]);

  return (
    <section className="content-wrapper about-us">
      {pageData && pageData.length > 0 ? (
        <div className="ania-content pt-lg-1 pt-0">
          <div className="container-fluid px-0">
            <div className="main-content">
              <div className="col-lg-12 px-lg-5 px-4">
                <h1 className="page-title page-title-lg mb-0">
                  {pageData[0].pageTitle}
                </h1>
              </div>
            </div>
          </div>
          <div className="container-fluid px-0">
            <div className="about-us-img">
              <img
                src={pageData[0].mobileImg}
                alt="about us mobile img"
                className="mobile-img img-main"
              />
              <img
                src={pageData[0].desktopImg}
                alt="about us desktop img"
                className="desktop-img img-main"
              />
            </div>
            <div className="container-width">
              <div className="about-us-content">
                <h2 className="text-center">{pageData[0].title}</h2>
                <p>{pageData[0].p1}</p>
                <p>{pageData[0].p2}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1
          style={{ textAlign: "center", margin: "120px 50px", color: "grey" }}
        >
          {" "}
          No Data Found For This Url
        </h1>
      )}
    </section>
  );
}

export default Management;
