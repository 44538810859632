/**
 * faq.js
 * copyright © 2022 Ania Management
 */
import React, { useState, useEffect } from "react";
import { faqData } from "../faq";
import parse from "html-react-parser";

import faqicon1 from "../assets/images/faq-category-icon-1.svg";
import faqicon4 from "../assets/images/faq-category-icon-2.svg";
import faqicon2 from "../assets/images/faq-category-icon-3.svg";
import faqicon3 from "../assets/images/faq-category-icon-4.svg";
import faqicon5 from "../assets/images/faq-category-icon-5.svg";

function FaqPage() {
  //for putting data of faq's to state on page load
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [title, setTitle] = useState("All Questions");
  const [searchData, setSearchData] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  //for opening page from top whenever user lands on this page
  useEffect(() => {
    setData(faqData);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (searchData === "") {
      showAllQuestions();
    }
  }, [searchData]);

  const showAllQuestions = () => {
    setSearchResult([]);
    setData(faqData);
    setTitle("All Questions");
  };

  //for updating data of faq's to state on filter
  const onClickHandler = (category) => {
    setSearchResult([]);
    let updateData = faqData.filter((val) => val.category === category);
    if (updateData && updateData.length > 0) {
      setActiveTab(category);
      setTitle(category);
      setData([...updateData]);
    } else {
      setTitle("No Result");
      setData([]);
    }
  };

  //for updating data of faq's to state on search
  const onSearchHandler = (e, data) => {
    e.preventDefault();
    if (data !== "") {
      let searchKey = data.toLowerCase();

      let searchedData = faqData
        .map(
          (catObj, i) =>
            catObj.questionArray.filter((quesObj, j) =>
              quesObj.question.toLowerCase().includes(searchKey)
            )[0]
        )
        .filter((obj, i) => obj);

      if (searchedData && searchedData.length > 0) {
        setSearchResult(searchedData);
        setActiveTab("");
        setTitle("Search Results");
      } else {
        setTitle("No Result");
      }
    }
  };
  return (
    <section className="content-wrapper faqs">
      <div className="ania-content faqs-section-top">
        <div className="container-fluid px-0">
          <div className="main-content">
            <div className="col-lg-12 px-lg-5 px-5">
              <h1 className="faq page-title page-title-lg mb-2">
                Hello, how can we help?
              </h1>
              <h6>Frequently Asked Questions</h6>
            </div>
          </div>
        </div>
        <div className="container-width">
          <form
            className="faq-ania-form ania-form"
            onSubmit={(e) => onSearchHandler(e, searchData)}
          >
            <div className="form-group d-lg-flex d-block">
              <input
                type="text"
                name="search"
                className="form-control"
                placeholder="Ask A Question..."
                value={searchData || ""}
                onChange={(e) => setSearchData(e.target.value)}
              />
              <button
                type="submit"
                className="btn btn-primary"
                // onClick={(e) => onSearchHandler(searchData)}
              >
                Search
              </button>
            </div>
          </form>
          <p>or choose a category to quickly find the help you need</p>
        </div>
      </div>
      <div className="faqs-listing">
        <div className="container-width">
          <div className="faqs-category-buttons">
            <div className="row">
              <div className="col-md-12 d-flex">
                <button
                  style={
                    activeTab && activeTab === "Prospective Tenants"
                      ? { border: "1px solid #007CBA" }
                      : null
                  }
                  onClick={() => {
                    onClickHandler("Prospective Tenants");
                  }}
                >
                  <span className="icon">
                    <img
                      src={faqicon4}
                      style={
                        activeTab && activeTab === "Prospective Tenants"
                          ? {
                              filter:
                                "invert(43%) sepia(93%) saturate(5092%) hue-rotate(180deg) brightness(90%) contrast(101%)",
                            }
                          : null
                      }
                      alt="Prospective Tenants"
                    />
                  </span>
                  Prospective Tenants
                </button>
                <button
                  style={
                    activeTab && activeTab === "FTI Group"
                      ? { border: "1px solid #007CBA" }
                      : null
                  }
                  onClick={() => {
                    onClickHandler("FTI Group");
                  }}
                >
                  <span className="icon">
                    <img
                      src={faqicon5}
                      style={
                        activeTab && activeTab === "FTI Group"
                          ? {
                              filter:
                                "invert(43%) sepia(93%) saturate(5092%) hue-rotate(180deg) brightness(90%) contrast(101%)",
                            }
                          : null
                      }
                      alt="FTI Group"
                    />
                  </span>
                  FTI Group
                </button>

                <button
                  style={
                    activeTab && activeTab === "Maintenance"
                      ? { border: "1px solid #007CBA" }
                      : null
                  }
                  onClick={() => {
                    onClickHandler("Maintenance");
                  }}
                >
                  <span className="icon">
                    <img
                      src={faqicon3}
                      style={
                        activeTab && activeTab === "Maintenance"
                          ? {
                              filter:
                                "invert(43%) sepia(93%) saturate(5092%) hue-rotate(180deg) brightness(90%) contrast(101%)",
                            }
                          : null
                      }
                      alt="Maintenance"
                    />
                  </span>
                  Maintenance
                </button>
                <button
                  style={
                    activeTab && activeTab === "Security"
                      ? { border: "1px solid #007CBA" }
                      : null
                  }
                  onClick={() => {
                    onClickHandler("Security");
                  }}
                >
                  <span className="icon">
                    <img
                      src={faqicon2}
                      style={
                        activeTab && activeTab === "Security"
                          ? {
                              filter:
                                "invert(43%) sepia(93%) saturate(5092%) hue-rotate(180deg) brightness(90%) contrast(101%)",
                            }
                          : null
                      }
                      alt="Security"
                    />
                  </span>
                  Security
                </button>

                {/* <button
                  style={
                    activeTab && activeTab === "All Questions"
                      ? { border: "1px solid #007CBA" }
                      : null
                  }
                  onClick={() => showAllQuestions()}
                >
                  <span className="icon">
                    <img
                      src={faqicon2}
                      style={
                        activeTab && activeTab === "All Questions"
                          ? {
                              filter:
                                "invert(43%) sepia(93%) saturate(5092%) hue-rotate(180deg) brightness(90%) contrast(101%)",
                            }
                          : null
                      }
                      alt="All Questions"
                    />
                  </span>
                  All Questions
                </button> */}
              </div>
            </div>
          </div>

          <div className="faqs-listin-head text-center">
            <h2>{title}</h2>
          </div>
          {/* Search Result */}
          <div className="allfaqs" id="accordion">
            {searchResult && searchResult.length > 0
              ? searchResult.map((val, i) => {
                  return (
                    <div key={i} className="card">
                      <div className="card-header">
                        <button
                          className={"btn " + (i !== 0 ? "collapsed" : "")}
                          data-toggle="collapse"
                          data-target={`#${i}`}
                          aria-expanded="false"
                          aria-controls={i}
                        >
                          {val.question}
                        </button>
                      </div>
                      <div
                        id={i}
                        className={"collapse " + (i === 0 ? "show" : "")}
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p>{parse(val.answer)}</p>
                        </div>
                      </div>
                    </div>
                  );
                  // });
                })
              : data && data.length > 0
              ? data.map((val, i) => {
                  return val.questionArray.map((qa, j) => {
                    return (
                      <div key={"" + i + j} className="card">
                        <div className="card-header">
                          <button
                            className={
                              "btn " + ("" + i + j !== 0 ? "collapsed" : "")
                            }
                            data-toggle="collapse"
                            data-target={`#${"" + i + j}`}
                            aria-expanded="false"
                            aria-controls={"" + i + j}
                          >
                            {qa.question}
                          </button>
                        </div>
                        <div
                          id={"" + i + j}
                          className={
                            "collapse " + ("" + i + j === 0 ? "show" : "")
                          }
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            {qa.answer === "Please use Tenant Portal. " ? (
                              <p>
                                Please use{" "}
                                <a
                                  href="https://webapp.floriomanagement.com/"
                                  style={{ color: "#007CBA" }}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Tenant Portal
                                </a>
                              </p>
                            ) : (
                              <p>{parse(qa.answer)}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  });
                })
              : null}
          </div>
        </div>
      </div>
    </section>
  );
}
export default FaqPage;
