/**
 * careers.js
 * copyright © 2022 Ania Management
 */
import React, { useState, useEffect } from "react";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { Select, FormControl, MenuItem } from "@mui/material";
import Home from "../pages/home";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ReactMap from "../components/reactMap";
import "react-toastify/dist/ReactToastify.css";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { apiCall } from "../components/apiManager";
import { normalizeInput } from "../components/numberFormatter";
import "../assets/css/contact.css";
import "../assets/css/careers.css";
import { FileUpload } from "@mui/icons-material";

function Careers() {
  //for opening page from top whenever user lands on this page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    content: "",
  };

  //variables for managing local state
  const [formValues, setFormValues] = useState(initialValues);
  const [error, setError] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [attachment, setAttachment] = useState(false);
  const [attachmentError, setAttachmentError] = useState("");

  let { name, email, phone, content } = formValues;

  //for handling captcha
  const handleVerify = async (response) => {
    if (!response) {
      setCaptchaVerified(true);
    }
    if (response && !captchaVerified) {
      setCaptchaVerified(true);
    }
  };

  //for changing values and update state
  const handleChange = async (e) => {
    let { name, value } = e.target;
    if (name === "phone") {
      value = await normalizeInput(value, phone);
    }
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const fileUpload = (event) => {
    if (event.target.files[0] && event.target.files[0].size > 5000000) {
      setAttachmentError(true);
      return;
    } else {
      setError(false);
      setAttachment(event.target.files[0]);
    }
  };

  //for submiting form on click
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setError(true);
      let { name, email, phone, content } = formValues;
      if (!name || !email || !phone || !captchaVerified) {
        return;
      }

      if (
        email &&
        !email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        return;
      }

      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("content", content);
      formData.append("attachment", attachment);

      if (formData) {
        let data = await apiCall("post", "career", formData, {});
        if (data) {
          setError(false);
          setFormValues({
            name: "",
            email: "",
            phone: "",
            content: "",
          });
          setAttachment(false);
          setCaptchaVerified(false);
          toast.success("Form Successfully submitted!");
        } else {
          toast.error(data.message, "Something bad happened");
        }
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="main">
      <section className="content-wrapper contact-us">
        <div className="ania-content pt-lg-1 pt-0 pb-0">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 px-lg-5 border-bottom px-4 order-lg-1 order-3 hide-mobile">
                <div className="main-content py-lg-4 pb-4">
                  <h1 className="page-title page-title-lg mb-0 ">
                    Find a career at ANIA Management
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4 dekstop-hide">
            <div className="embed-responsive embed-responsive">
              <ReactMap
                center={[{ lat: 40.91886955304371, lng: -74.16767144523963 }]}
              />
            </div>
          </div>
          <div className="breadcrumb-width">
            <div className="col-lg-12 mb-lg-4 hide-mobile-1">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" className="link" component={<Home />}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Careers
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="container-width">
            <div className="main-content">
              <div className="row justify-content-center align-items-top">
                <div className="col-lg-12 mb-lg-4  order-lg-2 order-2 dekstop-hide-1">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/" className="link" component={<Home />}>
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Careers
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-lg-12 order-3 dekstop-hide">
                  <h1 className="page-title page-title-lg mb-0  ">
                    Find a career at ANIA Management
                  </h1>
                </div>
                <div className="col-lg-12  order-lg-3 order-4">
                  <p className="form-title">
                    Reach out to learn about employment opportunities.
                  </p>
                </div>
                <div className="col-lg-7  order-lg-4 order-5">
                  <GoogleReCaptchaProvider
                    reCaptchaKey="6LdF5jceAAAAAMF1RMx5RaNMK9tke3RzRRGEEwX_"
                    // "6LfPKsEeAAAAAES3zCFfW_ywnjmYym8zHKFS_Pc0"
                  >
                    <form className="ania-form">
                      <div className="mb-3">
                        <input
                          type="text"
                          name="name"
                          className={`form-control ${
                            error && !name ? "input-invalid" : ""
                          }`}
                          placeholder="Full Name*"
                          id=""
                          value={name}
                          onChange={handleChange}
                        />
                        <p className="error-msg">
                          {error && !name && "Full Name is required"}
                        </p>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          name="email"
                          className={`form-control ${
                            error && !email ? "input-invalid" : ""
                          }`}
                          placeholder="Your Email*"
                          id=""
                          value={email}
                          onChange={handleChange}
                        />
                        <p className="error-msg">
                          {error && !email
                            ? "Email is required"
                            : error &&
                              email &&
                              !email.match(
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                              )
                            ? "Incorrect email address"
                            : null}
                        </p>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          name="phone"
                          className={`form-control ${
                            error && !phone ? "input-invalid" : ""
                          }`}
                          placeholder="Your Telephone*"
                          id=""
                          value={phone}
                          onChange={handleChange}
                        />
                        <p className="error-msg">
                          {error && !phone
                            ? "Number is required"
                            : error && phone && phone.length < 14
                            ? "Invalid phone number"
                            : null}
                        </p>
                      </div>
                      <div className="mb-3 button">
                        <input
                          type="file"
                          id="actual-btn"
                          hidden
                          onChange={(e) => fileUpload(e)}
                        />

                        <div id="file-chosen">
                          <div className="upload-main">Upload your Resume</div>
                          <div className="upload-info">
                            PDF or Doc (max file size: 5mb)
                          </div>
                        </div>

                        <label className="career-label" htmlFor="actual-btn">
                          Upload File
                        </label>
                      </div>
                      <p className="error-msg">
                        {attachmentError
                          ? "Invalid file size."
                          : attachment
                          ? "File name : " + attachment.name
                          : ""}
                      </p>

                      <div className="mb-3">
                        <textarea
                          type="text"
                          name="content"
                          className="form-control"
                          placeholder="Message"
                          rows="7"
                          value={content}
                          onChange={handleChange}
                          id=""
                        />
                        {/* <p className="error-msg">
                          {error && !content && "Message is required"}
                        </p> */}
                      </div>
                      <div className="row" style={{ height: "100px" }}>
                        <div className="col-lg-6">
                          <button
                            type="submit"
                            onClick={handleSubmit}
                            className="contact-btn btn btn-primary btn-block btn-ania"
                          >
                            Submit
                          </button>
                          <p className="error-msg">
                            {error && !captchaVerified && "You are not human"}
                          </p>
                          <ToastContainer />
                        </div>
                        <div className="col-lg-6">
                          <GoogleReCaptcha onVerify={handleVerify} />
                        </div>
                      </div>
                      <p className="career-content">
                        ANIA Management is commited to protecting your
                        information security. Your information will be used in
                        accordance with any applicable data privacy law and
                        ANIA’s Data Privacy Policy and will be held securely.
                        ANIA Management will not share your information with any
                        third party, except as needed to manage the job
                        application. Please review our complete{" "}
                        <u>
                          <a href="/privacy-policy">privacy notice</a>
                        </u>{" "}
                        for additional information.
                      </p>
                    </form>
                  </GoogleReCaptchaProvider>
                </div>
                <div className="align-left col-lg-5  order-lg-5 order-6">
                  <div className="head-office mt-lg-0 mt-5">
                    <p className="title">head office</p>
                    <p className="company-name">ania</p>
                    <p className="office-address">
                      175 Broadway
                      <br />
                      Paterson, NJ 07505
                    </p>
                    <p className="office-phone ">
                      Phone:{" "}
                      <a style={{ color: "#007CBA" }} href="tel:9735237690">
                        (973) 523-7690
                      </a>
                    </p>
                  </div>
                  <div className="office-hours">
                    <p className="title">office hours</p>
                    <p className="office-day">monday</p>
                    <p className="office-time">9:00am - 5:00pm EST</p>
                    <p className="office-day">Tuesday</p>
                    <p className="office-time">9:00am - 5:00pm EST</p>
                    <p className="office-day">Wednesday</p>
                    <p className="office-time">9:00am - 5:00pm EST</p>
                    <p className="office-day">Thursday</p>
                    <p className="office-time">9:00am - 5:00pm EST</p>
                    <p className="office-day">Friday</p>
                    <p className="office-time">9:00am - 5:00pm EST</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-4 hide-mobile">
            <div className="embed-responsive embed-responsive">
              <ReactMap
                center={[{ lat: 40.91886955304371, lng: -74.16767144523963 }]}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Careers;
