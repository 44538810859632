/**
 * findunitdetails.js
 * copyright © 2022 Ania Management
 */
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { apiCall } from "../components/apiManager";
import FindUnit from "../pages/findaunit";
import RentalPage from "./rentalpage";
import Slider from "react-slick";
import { SpinnerCircular } from "spinners-react";
import "../assets/css/home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { Modal } from "react-bootstrap";

function FindUnitDetail() {
  const { id } = useParams();
  //to navigate to new page on clicking view unit
  const navigate = useNavigate();
  const navigatePage = (id) => {
    let path = `/find-unit/detail/${id}`;
    navigate(path);
  };
  const messagesEndRef = useRef(null);
  const [unitData, setUnitData] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);
  const [nearbyPlaces, setNearbyPlaces] = useState([]);
  const [similarData, setSimilarData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openTourModal, setOpenTourModal] = useState(false)

  //updating slider value on mouse scroll, fetching data and storing to local state
  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      let { unitDetails, similarProperties, pointsOfInterest } = await apiCall(
        "get",
        `unit_property/getUnitDetails/${id}`,
        null,
        {}
      );
      if (unitDetails && unitDetails.length > 0) {
        setUnitData(unitDetails);
        setSliderImages([
          ...unitDetails[0].propertyImages,
          ...unitDetails[0].unitImages,
        ]);
        setSimilarData(similarProperties);
        setNearbyPlaces(pointsOfInterest);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    fetchData();
  }, [id]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  //default setting values for craousel
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "299px",
    slidesToShow: 1,
    slideToScroll: 1,
    speed: 3000,
    arrows: true,
    accessibility: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "200px",
          slidesToShow: 1,
          speed: 3000,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "40px",
          slidesToShow: 1,
          speed: 3000,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "40px",
          slidesToShow: 1,
          speed: 3000,
          arrows: false,
        },
      },
    ],
  };


  const renderTourModal = ( ) =>{
   return (<Modal centered size="xl" show={openTourModal}>
                      <Modal.Header
                        closeButton
                        className="border-0"
                        onHide={()=> setOpenTourModal(false)}
                      ></Modal.Header>
                      <Modal.Body className="d-flex flex-column align-items-center">
                        <iframe style={{width: "100%", height: "80vh"}} src="https://view.ricoh360.com/0e1f3e18-8219-4a6f-b147-2dd1b48a3423?type=compact"></iframe>
                      </Modal.Body>
                    </Modal>)
  }

  return (
    <>
      {loading && (
        <div style={{ display: "flex" }}>
          <SpinnerCircular style={{ width: "30%", height: "40vh" }} />
        </div>
      )}
      {unitData && unitData.length > 0 ? (
        <div className="main">
          <section className="content-wrapper find-a-unit detail">
            <div className="ania-content pt-lg-1 pt-0">
              <div className="container-fluid px-0">
                <div className="main-content">
                  <div className="col-lg-12 px-lg-5 px-4">
                    <h1 className="page-title page-title-lg mb-0">
                      Find a Unit
                    </h1>
                  </div>
                </div>
                <div className="border-top breadcrumb-nav">
                  <div className="breadcrumb-width">
                    <div className="col-lg-12 mb-lg-4 hide-mobile-1">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link
                              className="link"
                              to="/find-unit"
                              component={<FindUnit />}
                            >
                              Back to Search
                            </Link>
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="col-lg-12 mb-lg-4  order-lg-2 order-2 dekstop-hide-1">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="link"
                            to="/find-unit"
                            component={<FindUnit />}
                          >
                            Back to Search
                          </Link>
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="col-lg-12 pr-lg-5 px-0 custom-padding">
                    {unitData.map((val, i) => (
                      <div key={i} className="d-lg-flex max-height-1">
                        <div className="col-lg-7 px-lg-3 px-0">
                          <div className="properties-card">
                            <img
                              src={
                                val?.imagePrimary
                                  ? val?.imagePrimary 
                                  : (val?.unitImages?.length>0 
                                  && val?.unitImages[0]['Unit_NOTE_Images::ArchiveURL'])
                                  ? val?.unitImages[0]['Unit_NOTE_Images::ArchiveURL']
                                  : "/assets/images/imageNotAvailable.png"
                              }
                              className={
                                val?.imagePrimary
                                  ? "unit-image"
                                  : "unit-image-notAvailable"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div className="fixed-height">
                            <div className="d-flex flex-column align-items-start justify-content-between">
                              <div className="propterties-card-body">
                                <div style={{ letterSpacing: "0.2em" }}>
                                  <p className="location py-lg-4 py-3 mb-0">
                                    <span className="text-uppercase city">
                                      {val?.Address_City}
                                    </span>
                                    {/* <span>&nbsp;/&nbsp;</span>
                                    <span className="text-uppercase place">
                                      sand hill
                                    </span> */}
                                  </p>
                                </div>
                                <div>
                                  {val?.Stat_BedroomCount ||
                                  val?.Stat_BathroomCount ? (
                                    <p className="type margin-top">
                                      {val?.Stat_BedroomCount} Bedroom,{" "}
                                      {val?.Stat_BathroomCount} Bath
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div>
                                  {val && val?.propertySize === "L" ? (
                                    <p
                                      className="address-font border-bottom pb-3"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {/* {val?.Address_Address01_finalDisply.toLowerCase()}, {val?.Address_State} */}
                                      {val?.Address_City}, {val?.Address_State}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="price-and-availability mb-2 border-0">
                                  <div className="row px-3 justify-content-start align-items-center">
                                    <div className="price">
                                      {val?.MonthlyRent ? (
                                        <div>
                                          <p className="net-price">
                                            Starting at
                                          </p>
                                          <p className="value">
                                            $
                                            {val?.MonthlyRent?.toFixed().replace(
                                              /(\d)(?=(\d{3})+(?!\d))/g,
                                              "$1,"
                                            )}
                                          </p>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="availability">
                                      <p className="net-availability">
                                        Available
                                      </p>
                                      <p className="value">Now</p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="property-description"
                                >
                                  <p className="property-description-title" style={{textTransform: "capitalize"}}>
                                   Street
                                  </p>
                                  <p className="property-description-content mb-3" style={{textTransform: "capitalize"}}>
                                    {`${val["Unit_PROP::zzc_Address_DropBox_StreetNameOnly"]?.toLowerCase() || ''} ${val["Unit_PROP::zzc_Address_DropBox_StreetExtensionOnly"]?.toLowerCase() || ''}`}
                                  </p>
                                 </div>
                                <div
                                  className="property-description"
                                  style={{ padding: "15px 0" }}
                                >
                                  <p className="property-description-title">
                                    What this place offers:
                                  </p>
                                  <p className="property-description-content">
                                    {val?.unitDescription}
                                  </p>
                                  {val && val?.floorPlan ? (
                                    <a
                                      href="/home"
                                      className="download-link text-uppercase"
                                    >
                                      download floor plan
                                    </a>
                                  ) : (
                                    <div className="download-link text-uppercase"></div>
                                  )}
                                </div>
                                <div className="d-flex justify-content-start mobile-center">
                                  <button
                                    className="btn btn-outline-secondary text-capitalize"
                                    onClick={scrollToBottom}
                                  >
                                    inquire
                                  </button>
                                  {/* <button
                                    className="btn btn-outline-secondary text-capitalize d-flex flex-column align-items-center justify-content-center"
                                    onClick={()=>{
                                      // window.open("https://view.ricoh360.com/0e1f3e18-8219-4a6f-b147-2dd1b48a3423?type=compact")
                                      setOpenTourModal(true);
                                    }}
                                    style={{marginLeft: 200, paddingTop: 5, paddingBottom: 5, paddingLeft: 32, paddingRight: 32}}
                                  >
                                    <CameraAltOutlinedIcon />
                                    <span>Virtual Tour</span>
                                  </button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {sliderImages && sliderImages.length > 0 ? (
                  <div className="col-lg-12 px-0">
                    <div className="property-content">
                      <h2>Photo Gallery</h2>
                    </div>
                    <div>
                      <Slider {...settings}>
                        {sliderImages.map((val, i) => (
                          <div key={i} className="slide">
                            <img
                              src={
                                val["Unit_NOTE_ImagesProperty::ArchiveURL"]
                                  ? val["Unit_NOTE_ImagesProperty::ArchiveURL"]
                                  : val["Unit_NOTE_Images::ArchiveURL"]
                                  ? val["Unit_NOTE_Images::ArchiveURL"]
                                  : ""
                              }
                              alt={val?.recordId}
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                ) : null}
                {similarData && similarData.length > 0 ? (
                  <div className="full-width col-lg-12 pl-lg-5 pr-lg-5 similar-apartments">
                    <div className="property-content ">
                      <h2>similar apartments</h2>
                    </div>
                    <div className="row">
                      {similarData.map((val, i) => (
                        <div key={i} className="col-lg-4 custom-margin">
                          <div className="properties-card">
                            <img
                              src={
                                val?.imagePrimaryThumb
                                  ? val?.imagePrimaryThumb
                                  : "/assets/images/imageNotAvailable.png"
                              }
                              className={
                                val?.imagePrimaryThumb
                                  ? "img-fluid w-100"
                                  : "img-fluid w-100 absentImageThumb"
                              }
                              style={
                                val?.imagePrimaryThumb
                                  ? {
                                      height: "290px",
                                      objectFit: "cover",
                                      objectPosition: "center",
                                    }
                                  : {
                                      height: "130px",
                                      objectFit: "cover",
                                      objectPosition: "center",
                                    }
                              }
                              alt={val?.Address_Address01_finalDisply}
                              onClick={() => navigatePage(val?.zzID)}
                            />
                            <div
                              className={
                                val?.imagePrimaryThumb
                                  ? "propterties-card-body"
                                  : "propterties-card-body"
                              }
                            >
                              <p className="location mb-0">
                                <span className="city">
                                  {val?.Address_City}
                                </span>
                                <span>&nbsp;</span>
                                {/* <span className="address">{val?.Address_Address01_finalDisply.substring(0, 20)}</span> */}
                                <span className="address">
                                  {val?.Address_State}
                                </span>
                              </p>
                              {val?.Stat_BedroomCount ||
                              val?.Stat_BathroomCount ? (
                                <p className="type">
                                  {val?.Stat_BedroomCount} Bedroom,{" "}
                                  {val?.Stat_BathroomCount} Bath
                                </p>
                              ) : (
                                ""
                              )}
                              <div className="price-and-availability ">
                                <div className="row justify-content-between align-items-center">
                                  <div className="price px-3">
                                    {val?.MonthlyRent &&
                                    val?.MonthlyRent !== null ? (
                                      <div>
                                        <p className="net-price">Starting at</p>
                                        <p className="value">
                                          $
                                          {val?.MonthlyRent &&
                                          val?.MonthlyRent !== null
                                            ? val?.MonthlyRent.toFixed().replace(
                                                /(\d)(?=(\d{3})+(?!\d))/g,
                                                "$1,"
                                              )
                                            : ""}
                                        </p>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="availability">
                                    <p className="net-availability">
                                      Availability
                                    </p>
                                    <p className="value">Now</p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <button
                                  className="btn btn-outline-none text-uppercase"
                                  onClick={() => navigatePage(val.zzID)}
                                >
                                  view unit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
                {nearbyPlaces && nearbyPlaces.length > 0 ? (
                  <>
                    <div className="property-content">
                      <h2 className="mb-0">Nearby Places</h2>
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        {nearbyPlaces.map((val, i) => (
                          <div key={i} className="col-lg-4 mt-5">
                            <a href={val?.GoogleURL} target="blank">
                              <div className="featured-block mb-0">
                                <img
                                  src={val?.ImageURL}
                                  className="img-fluid nearby-img"
                                  alt={val?.Address}
                                />
                                <div className="featured-caption">
                                  <p className="featured-caption-text">
                                    {val?.Name} {val?.Distance} miles
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </section>
          <div ref={messagesEndRef}>
            <RentalPage
              data={{
                subject: `Unit Inquiry: ${unitData[0].Address_Address01_finalDisply} Unit ${unitData[0].Number}`,
                propertyAddress: unitData[0].Address_Address01_finalDisply,
                unitNumber: unitData[0].Number,
                rentAmount: `$${unitData[0].MonthlyRent}`,
                content: `I'm interested in ${unitData[0].Address_Address01_finalDisply}`,
                zzid_UNIT: id
              }}
            />
          </div>
        </div>
      ) : !loading && unitData.length === 0 ? (
        <h1 style={{ textAlign: "center" }}> No Data Found For This Id</h1>
      ) : null}
      {renderTourModal()}
    </>
  );
}

export default FindUnitDetail;
